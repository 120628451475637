<template>
    <div>
        <v-toolbar flat class="my-3" height="36">
            <v-toolbar-title>Account administratie</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                    @click="changeAdministrationNotifications = true"
                    class="mr-3"
                >
                    {{
                        $localize('ChangeAdministrationNotifications.button')
                    }}</v-btn
                >
                <v-btn @click="changePaymentInformation = true">
                    {{ $localize('ChangePaymentInformation.button') }}</v-btn
                >
            </v-toolbar-items>
        </v-toolbar>

        <v-alert type="warning" v-if="$session.licenseStatus === 'license-limit-reached'">
            U heeft het maximale project limiet bereikt van uw licentie. Momenteel kunnen er geen projecten gemaakt worden. <br>
            Upgrade uw licentie door op het <v-icon small>mdi-pencil</v-icon> te klikken. Of wacht tot het begin van de volgende maand.
        </v-alert>
        <div v-if="licenses.length === 0">U heeft momenteel geen licentie</div>
        <v-row wrap>
            <v-col cols="6" v-if="licenses.length > 0">
                <v-row wrap dense>
                    <v-col cols="6" class="font-weight-bold"
                        >Huidige Licentie
                        <v-icon
                            v-if="licenses.length <= 1"
                            small
                            @click="changingLicense = true"
                            >mdi-pencil</v-icon
                        >
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="6">Bedrag per maand</v-col>
                    <v-col cols="6">{{
                        $format.currency(licenses[0].amount)
                    }}</v-col>
                    <v-col cols="6">Projecten per maand</v-col>
                    <v-col cols="6">{{ licenses[0].maxProjects }}</v-col>
                    <v-col v-if="licenses[0].end" cols="6">Einddatum</v-col>
                    <v-col v-if="licenses[0].end" cols="6">{{
                        $format.shortDate(licenses[0].end)
                    }}</v-col>
                    <v-col cols="6">Projecten deze maand</v-col>
                    <v-col cols="6">{{ totalProjectCount }}</v-col>
                </v-row>
            </v-col>
            <v-col cols="6" v-if="licenses.length > 1">
                <v-row wrap dense>
                    <v-col cols="6" class="font-weight-bold"
                        >Aankomende Licentie</v-col
                    >
                    <v-col cols="6"></v-col>
                    <v-col cols="6">Bedrag per maand</v-col>
                    <v-col cols="6">{{
                        $format.currency(licenses[1].amount)
                    }}</v-col>
                    <v-col cols="6">Projecten per maand</v-col>
                    <v-col cols="6">{{ licenses[1].maxProjects }}</v-col>
                    <v-col cols="6">Begindatum</v-col>
                    <v-col cols="6">{{
                        $format.shortDate(licenses[1].start)
                    }}</v-col>
                    <v-col cols="6" v-if="licenses[1].end">Einddatum</v-col>
                    <v-col cols="6" v-if="licenses[1].end">{{
                        $format.shortDate(licenses[1].end)
                    }}</v-col>
                    <v-col cols="12">
                        <v-btn
                            small
                            @click="cancelLicense(licenses[1].licenseId)"
                            >Licentie wijziging annuleren</v-btn
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else class="text-center">
            </v-col>
        </v-row>
        <v-toolbar flat class="my-3" height="36">
            <v-toolbar-title>Facturen</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="setupMandate = true">
                    {{ $localize('SetupMandate.button') }}</v-btn
                >
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :items="invoices" :headers="invoiceHeaders">
            <template v-slot:[`item.created`]="{ item }">{{
                $format.shortDate(item.created)
            }}</template>
            <template v-slot:[`item.periodStart`]="{ item }">
                {{ $format.shortDate(item.periodStart) }} -
                {{ $format.shortDate(item.periodEnd) }}
            </template>
            <template v-slot:[`item.amount`]="{ item }">{{
                $format.currency(item.totalAmount)
            }}</template>
            <template v-slot:[`item.download`]="{ item }">
                <v-icon
                    v-if="item.path"
                    @click="downloadInvoice(item)"
                    class="red--text"
                    >mdi-adobe-acrobat</v-icon
                >
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <template v-if="item.status === 'paid' || item.paid">
                    <v-icon small class="success--text"
                        >mdi mdi-check-circle-outline</v-icon
                    >
                    Betaald op {{ $format.shortDate(item.paidAt) }}
                </template>
                <template v-else-if="item.status === 'open'">
                    <v-icon small class="warning--text"
                        >mdi mdi-progress-upload</v-icon
                    >
                    Open
                </template>
                <template v-else-if="item.status === 'pending'">
                    <v-icon small class="success--text"
                        >mdi mdi-cog-outline</v-icon
                    >
                    Word verwerkt
                </template>
                <template v-else-if="item.status === 'cancelled'">
                    <v-icon small class="success--text">mdi mdi-close</v-icon>
                    Geannuleerd
                </template>
                <template v-else-if="item.status === 'expired'">
                    <v-icon small>mdi mdi-clock-outline</v-icon>
                    Betaling verlopen
                </template>
                <template v-else-if="item.status === 'failed'">
                    <v-icon small class="error--text"
                        >mdi mdi-circle-outline</v-icon
                    >
                    Mislukt
                </template>
                <template v-else>
                    <v-icon small>mdi mdi-information-outline</v-icon>
                    Nog geen betaling
                </template>
            </template>
            <template v-slot:[`item.pay`]="{ item }">
                <v-btn
                    v-if="canStartPayment(item)"
                    @click="createPaymentOneOf(item.invoiceId)"
                    >Betalen</v-btn
                >
                <v-btn
                    v-if="canResumePayment(item)"
                    @click="resumePaymentOneOf(item.invoiceId)"
                    >Betalen</v-btn
                >
                <v-btn
                    v-if="canCancelPayment(item)"
                    @click="cancelPaymentOneOf(item.invoiceId)"
                    >Annuleren</v-btn
                >
            </template>
        </v-data-table>
        <cancel-license
            v-model="cancellingLicense"
            :license-id="cancelLicenseId"
            @saved="refresh"
        />
        <change-license
            v-if="changingLicense"
            v-model="changingLicense"
            :current-license="licenses.length > 0 ? licenses[0] : null"
            @saved="refresh"
        />
        <change-administration-notifications
            v-if="changeAdministrationNotifications"
            v-model="changeAdministrationNotifications"
            @saved="refresh"
        />
        <change-payment-information
            v-if="changePaymentInformation"
            v-model="changePaymentInformation"
            @saved="refresh"
        />
        <setup-mandate
            v-if="setupMandate"
            v-model="setupMandate"
            @saved="refresh"
        />
    </div>
</template>
<script>
import ChangePaymentInformation from './dialogs/ChangePaymentInformation'
import ChangeAdministrationNotifications from './dialogs/ChangeAdministrationNotifications'
import CancelLicense from './dialogs/CancelLicense'
import ChangeLicense from './dialogs/ChangeLicense'
import SetupMandate from './dialogs/SetupMandate'
import { downloadInvoice, getAccountLicenseInfo } from '@/services/api'
import sendCommand from '@/services/sendCommand'

export default {
    components: {
        CancelLicense,
        ChangeLicense,
        ChangePaymentInformation,
        ChangeAdministrationNotifications,
        SetupMandate,
    },
    data() {
        return {
            cancellingLicense: false,
            cancelLicenseId: null,
            changingLicense: false,
            changePaymentInformation: false,
            changeAdministrationNotifications: false,
            setupMandate: false,
            licenses: [],
            invoices: [],
            totalProjectCount: 0,
            invoiceHeaders: [
                { text: 'Datum', value: 'created' },
                { text: 'Periode', value: 'periodStart' },
                { text: 'Bedrag', value: 'amount' },
                { text: 'Status', value: 'status' },
                { text: 'Pdf', value: 'download' },
                { text: 'Betalen', value: 'pay' },
            ],
            licenseTypes: {
                free: 'Gratis',
                standard: 'Betaald',
            },
        }
    },
    created() {
        this.refresh()
    },
    methods: {
        async refresh() {
            const { result, ok } = await getAccountLicenseInfo.call()
            if (ok) {
                this.licenses = result.licenses
                this.invoices = result.invoices
                this.totalProjectCount = result.projectCount
            }
        },
        cancelLicense(licenseId) {
            this.cancelLicenseId = licenseId
            this.cancellingLicense = true
        },
        canStartPayment(invoice) {
            if (!invoice.paid && invoice.paymentId === null) {
                return true
            }
            if (invoice.status === 'expired') {
                return true
            }
            return false
        },
        canResumePayment(invoice) {
            if (invoice.paymentId !== null) {
                if (invoice.status === 'open') {
                    return true
                }
            }
            return false
        },
        canCancelPayment(invoice) {
            if (invoice.paymentId !== null) {
                if (invoice.status === 'pending') {
                    return true
                }
            }
            return false
        },
        downloadInvoice(invoice) {
            const url = downloadInvoice.create({
                invoiceId: invoice.invoiceId,
            }).url

            window.open(url)
        },
        async createPaymentOneOf(invoiceId) {
            await sendCommand('CreateInvoicePaymentOneOf', {
                invoiceId: invoiceId,
            })
        },
        async resumePaymentOneOf(invoiceId) {
            await sendCommand('ResumeInvoicePaymentOneOf', {
                invoiceId: invoiceId,
            })
        },
        async cancelPaymentOneOf(invoiceId) {
            await sendCommand('CancelInvoicePaymentOneOf', {
                invoiceId: invoiceId,
            })
        },
    },
}
</script>
<style scoped></style>
